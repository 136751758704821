angular.module('cpformplastApp')
  .controller('InfoJobModalController', function ($scope, Notification, DataManager, $state, job, item) {

    $scope.job = job;
    $scope.item = item;

    $scope.update = function() {
      $state.go('jobs/job-management/job-creation', {'jobId': $scope.job._id}, {reload:true})
      $scope.$dismiss()
    }

    $scope.close = function() {
      $state.go('jobs/job-management')
      $scope.$dismiss()
    }

    $scope.downloadJobCreationPdf = function(subjob) {
      DataManager.downloadJobCreationPdf(subjob);
    };

})
